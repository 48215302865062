import { AnnotatedManufacturer } from '../types/Manufacturer';
import { ProductImage } from '../api/services/imageService';

export const get_logo_url = (manufacturer: AnnotatedManufacturer): string | undefined => {
  if (!manufacturer.logo_filename) return
  return `https://scout-manufacturer-logos.nyc3.cdn.digitaloceanspaces.com/${manufacturer.logo_filename}`;
}

export const get_product_image_url = (image: ProductImage): string => {
  return `https://scout-product-images.nyc3.digitaloceanspaces.com/${encodeURIComponent(image.manufacturer_title.replace(/ /g, '_'))}/${encodeURIComponent(image.filename)}`;
}