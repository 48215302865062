import React, { useEffect, useState } from 'react';
import { ProductImage, getManufacturerProductImages, getManufacturerOmniclassProductImages } from '@/api/services/imageService';
import { get_product_image_url } from '@/utils/logos';
import { SectionTitle } from './SectionTitle';

interface ProductImageSectionProps {
  manufacturerTitle: string;
  omniclass?: string | null;
}

export const ProductImageSection: React.FC<ProductImageSectionProps> = ({
  manufacturerTitle,
  omniclass
}) => {
  const [images, setImages] = useState<ProductImage[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        setLoading(true);
        setError(null);
        
        const fetchedImages = omniclass
          ? await getManufacturerOmniclassProductImages(manufacturerTitle, omniclass)
          : await getManufacturerProductImages(manufacturerTitle);
        
        setImages(fetchedImages);
      } catch (err) {
        setError('Failed to load product images');
        console.error('Error fetching product images:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchImages();
  }, [manufacturerTitle, omniclass]);

  if (loading) {
    return <div className="w-full h-32 flex items-center justify-center">
      <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
    </div>;
  }

  if (images.length === 0 || error) {
    return null;
  }

  return (
    <div className="w-full">
      <SectionTitle>Product Images</SectionTitle>
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-4">
        {images.map((image, index) => (
          <div 
            key={index}
            className="aspect-square relative group overflow-hidden rounded-lg border border-gray-200 hover:border-gray-300 transition-all"
          >
            <img
              src={get_product_image_url(image)}
              alt={`Product ${index + 1}`}
              className="w-full h-full object-cover"
            />
          </div>
        ))}
      </div>
    </div>
  );
};
