/**
 * Utility function to check if a response from the backend is successful
 *
 * This is used for `service` functions which are sending data to the backend.
 *
 * @param response - The response from the backend
 * @param message - An optional message to include in the error if the response is not successful
 */
export const checkSuccess = async (response: Response, message?: string): Promise<void> => {
  if (!response.ok) {
    const additionalMsg = message ? ` - ${message}` : '';
    throw new Error(`Request failed with status ${response.status}${additionalMsg}`);
  }

  // assert that the response is JSON and contains a `message` key with the value "success"
  const data = await response.json();

  if (!data.message) {
    throw new Error("Response from backend did not contain a message");
  }

  if (data.message.toLowerCase() !== "success") {
    throw new Error(data.message);
  }

  return;
}


export const uploadCsvFiles = async (files: File[], uploadEndpoint: string, onSuccess: () => void) => {
  const fileReaders = files.map((file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.result === null) {
          reject('FileReader result is null');
          return;
        }
        // Remove the DataURL prefix
        const base64String = reader.result.toString().split(',')[1];
        resolve({'filename': file.name, 'content': base64String});
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  });

  const fileContents = await Promise.all(fileReaders);

  const response = await fetch(uploadEndpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(fileContents),
  });

  await checkSuccess(response).then(() => {
    onSuccess();
  });
}