import { apiEndpoints, adminEndpoints } from '../endpoints'

export interface ProductImage {
  filename: string;
  manufacturer_title: string;
  omniclass_name: string | null;
}

/**
 * Upload a single image file for a manufacturer
 * @param manufacturerTitle - The title of the manufacturer
 * @param file - The image file to upload
 * @param omniclassName - Optional name of the omniclass to associate with the image
 * @returns Promise<ProductImage> - The created ProductImage record
 */
export const uploadManufacturerImage = async (
  manufacturerTitle: string,
  file: File,
  omniclassName?: string | null
): Promise<ProductImage> => {
  // Convert the file to base64
  const reader = new FileReader();
  const imageData = await new Promise<string>((resolve, reject) => {
    reader.onload = () => {
      const base64 = reader.result as string;
      // Remove the data URL prefix (e.g., "data:image/jpeg;base64,")
      const base64Data = base64.split(',')[1];
      resolve(base64Data);
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });

  const url = adminEndpoints.manufacturer.upload_manufacturer_image.replace(':manufacturerTitle', encodeURIComponent(manufacturerTitle));
  
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      filename: file.name,
      image_data: imageData,
      omniclass_name: omniclassName || null,
    }),
  });

  if (!response.ok) {
    throw new Error('Failed to upload product image');
  }

  return response.json();
};

/**
 * Get all product images for a manufacturer
 * @param manufacturerTitle - The title of the manufacturer
 * @returns Promise<ProductImage[]> - List of product images
 */
export const getManufacturerProductImages = async (
  manufacturerTitle: string
): Promise<ProductImage[]> => {
  const url = apiEndpoints.get_manufacturer_images.replace(':manufacturerTitle', encodeURIComponent(manufacturerTitle));
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error('Failed to fetch manufacturer product images');
  }
  return response.json();
};

/**
 * Get product images for a manufacturer filtered by omniclass
 * @param manufacturerTitle - The title of the manufacturer
 * @param omniclassName - The name of the omniclass to filter by
 * @returns Promise<ProductImage[]> - List of filtered product images
 */
export const getManufacturerOmniclassProductImages = async (
  manufacturerTitle: string,
  omniclassName: string
): Promise<ProductImage[]> => {
  const url = apiEndpoints.get_manufacturer_omniclass_images
    .replace(':manufacturerTitle', encodeURIComponent(manufacturerTitle))
    .replace(':omniclassName', encodeURIComponent(omniclassName));
  
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error('Failed to fetch manufacturer omniclass product images');
  }
  return response.json();
};

/**
 * Get all product images for a manufacturer (admin interface)
 * @param manufacturerTitle - The title of the manufacturer
 * @returns Promise<ProductImage[]> - List of product images
 */
export const getAdminManufacturerProductImages = async (
  manufacturerTitle: string
): Promise<ProductImage[]> => {
  const url = adminEndpoints.manufacturer.get_admin_manufacturer_images
    .replace(':manufacturerTitle', encodeURIComponent(manufacturerTitle));
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error('Failed to fetch admin manufacturer product images');
  }
  return response.json();
};

/**
 * Update the omniclass association for a product image
 * @param manufacturerTitle - The title of the manufacturer
 * @param filename - The filename of the image
 * @param omniclassName - The name of the omniclass to associate with (null to remove association)
 * @returns Promise<ProductImage | null> - Updated product image or null if not found
 */
export const updateProductImageOmniclass = async (
  manufacturerTitle: string,
  filename: string,
  omniclassName: string | null
): Promise<ProductImage | null> => {
  const url = adminEndpoints.manufacturer.update_image_omniclass
    .replace(':manufacturerTitle', encodeURIComponent(manufacturerTitle))
    .replace(':filename', encodeURIComponent(filename));

  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ omniclass_name: omniclassName }),
  });
  
  if (!response.ok) {
    throw new Error('Failed to update product image omniclass');
  }
  
  return response.json();
};
